import { StyledMarkdown } from "@hlcr/ui";
import { useIntl } from "@hlcr/ui/Intl";
import { createStyles, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import { BugReport } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HistoryIcon from "@material-ui/icons/History";
import InfoOutlineIcon from "@material-ui/icons/InfoOutlined";
import * as React from "react";
import { useSelector } from "react-redux";


import { ImageContentCard } from "components/Cards/ImageContentCard";
import RegularCard from "components/Cards/RegularCard";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import ExamInfo from "components/Exam/ExamInfo";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import NavPills from "components/NavPills/NavPills";
import NoData from "components/NoData/NoData";
import { CriteriaGradingCatalogDto } from "components/Solution/CriteriaGradingCatalogDto";
import { SolutionHistory } from "components/Solution/SolutionHistory";
import SolutionSubmitDialog from "components/Solution/SolutionSubmitDialog";
import { isFinished } from "helper/dateCalc";
import usePersistedState from "helper/usePersistedState";
import { AssetType, GradingInstruction, Step } from "models/Asset";
import { EventUnitChallenge, EventUnitChallengeId, EventUnitMode, GradingMode } from "models/EventUnit";
import { SolutionComment, SolutionCommentSubmission } from "models/Solution";
import { RootState } from "reducers";
import { Event } from "shared/event/model/Event";
import TeacherVMs from "views/Teacher/TeacherVMs";


import DescriptionContent from "./DescriptionContent";
import { InfoSection } from "./InfoSection";
import Resources from "./Resources";


interface ChallengeComponentProps {
	challengeId: EventUnitChallengeId;
	event: Event;
	isPreview: boolean;
	isLoading: boolean;
	challenge: EventUnitChallenge;
	gradingInstruction: GradingInstruction;
	steps: Step[];
	solutionComments: SolutionComment[];
	fetchChallenge: (challengeId: EventUnitChallengeId, withGradingInstruction?: boolean) => void;
	fetchTeacherGradingInstructions: (challengeId: EventUnitChallengeId) => void;
	stepsLoading: boolean;
	fetchStep: () => void;
	fetchStepTitles: () => void;
	postSolutionComment: (solutionCommentSubmission: SolutionCommentSubmission, challengeId: EventUnitChallengeId, callback: () => void) => void;
}

export const ChallengeOverview = (props: ChallengeComponentProps) => {
	const { challengeId, challenge, event, isPreview, isLoading, fetchChallenge, fetchTeacherGradingInstructions } = props;

	const intl = useIntl();
	const classes = useStyles();

	const [ displayInfo, setDisplayInfo ] = usePersistedState("Challenge::ShowDisplayInfo", true);

	React.useEffect(() => {
		if (challengeId) {
			fetchChallenge(challengeId, true);
		}
		if (isPreview && challengeId) {
			fetchTeacherGradingInstructions(challengeId);
		}
	}, [ challengeId, isPreview, fetchChallenge, fetchTeacherGradingInstructions ]);

	const onSwitchDisplayInfo = () => {
		setDisplayInfo(!displayInfo);
	};

	if (isLoading && !challenge) {
		return <LoadingSpinner />;
	}

	return challenge ? (
		<div>
			<div className={classes.rightField}>
				{event && <ExamInfo event={event} isPreview={isPreview} showButton />}
			</div>
			<div>
				<h3 className={classes.title}>
					{challenge.title}
					{isPreview && ` (${intl.fm("event.preview")})`}
					{isLoading && (
						<CircularProgress style={{ marginLeft: 10 }} size={24} />
					)}
				</h3>
			</div>
			<GridContainer>
				<ItemGrid xs={12} sm={12} md={12} lg={4}>
					<ImageContentCard
						entity={challenge}
						assetType={AssetType.CHALLENGE}
						onSwitchDisplayInfo={onSwitchDisplayInfo}
						displayInfo={displayInfo}
					/>
				</ItemGrid>
				<ItemGrid xs={12} sm={12} md={6} lg={4}>
					<InfoSection
						challenge={challenge}
						event={event}
						unitType={AssetType.CHALLENGE}
						onSwitchDisplayInfo={onSwitchDisplayInfo}
						displayInfo={displayInfo}
						isPreview={isPreview}
					/>
				</ItemGrid>
				<ItemGrid xs={12} sm={12} md={6} lg={4}>
					<Resources challenge={challenge} event={event} />
				</ItemGrid>
				{isPreview && (
					<ItemGrid xs={12} sm={12} md={12} lg={12}>
						<TeacherVMs challenge={challenge} event={event} />
					</ItemGrid>
				)}
				<ItemGrid xs={12} sm={12} md={12} lg={12}>
					<ChallengeDetail {...props} />
				</ItemGrid>
			</GridContainer>
		</div>
	) : (
		<NoData />
	);
};

const ChallengeDetail = (
	{
		challengeId,
		challenge,
		steps,
		solutionComments,
		fetchChallenge,
		fetchStep,
		fetchStepTitles,
		postSolutionComment,
		isPreview,
		gradingInstruction,
		stepsLoading,
		event,
	}: ChallengeComponentProps) => {
	const intl = useIntl();
	const classes = useStyles();

	const darkMode = useSelector((state: RootState) => state.ui.darkMode);

	const [ isModalOpen, setModalOpen ] = React.useState(false);
	const [ activeTab, setActiveTab ] = React.useState(0);
	const [ disableMarkdown, setDisableMarkdown ] = React.useState(true);
	const [ penaltyPerSection, setPenaltyPerSection ] = React.useState(0);
	const [ penaltyPerSectionSteps, setPenaltyPerSectionSteps ] = React.useState(0);

	React.useEffect(() => {
		if (challenge.mode === EventUnitMode.STEPS_MODE) {
			const sectionsCount = challenge.sections?.length || 0;
			setPenaltyPerSection(Math.round(100 * challenge.stepTitlesPenalty / sectionsCount) / 100);
			setPenaltyPerSectionSteps(Math.round(100 * (1 - challenge.stepTitlesPenalty - challenge.fullPenaltyMaxGrade / sectionsCount)) / 100);
		}
	}, [ challenge ]);

	React.useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const tabParam = params.get("tab");

		if (tabParam === "0") {
			setActiveTab(0);
		}
		if (tabParam === "1" && solutionComments?.length > 0) {
			setActiveTab(1);
		}
	}, [solutionComments]);

	const hasSolution = solutionComments?.length > 0;

	const tabLinks = []; // tabLinks are navTabs without tabContent, e.g. if you just want a button to open a modal
	const navTabs = [];

	navTabs.push({
		tabButton: intl.fm("challenge.titles.description"),
		tabIcon: InfoOutlineIcon,
		tabContent: (
			<div className={classes.flagInputContainer}>
				<RegularCard content={
					<DescriptionContent
						isChallenge={true}
						entity={challenge}
						steps={steps}
						stepsLoading={stepsLoading}
						penaltyPerSection={penaltyPerSection}
						penaltyPerSectionSteps={penaltyPerSectionSteps}
						intl={intl}
						fetchStep={fetchStep}
						fetchStepTitles={fetchStepTitles} />
				} />
			</div>
		),
	});

	const hasSubmitSolutionButton = !isPreview && challenge && challenge.grading !== GradingMode.MANUAL;

	if (!isPreview) {
		if (hasSubmitSolutionButton) {
			tabLinks.push({
				text: "Submit Solution",
				icon: AddIcon,
				openTab: activeTab,
				onClicked: () => setModalOpen(true),
			});
		}

		// we only show solution history, if we already have a solution
		if (hasSolution) {
			navTabs.push({
				tabButton: intl.fm("challenge.titles.solution"),
				tabIcon: HistoryIcon,
				tabContent: (
					<RegularCard
						content={
							<>
								<InputLabel className={classes.historyMarkdownLabel}>
									{intl.fm("solution.history.markdown.disable")}
									<CustomSwitch checked={disableMarkdown} onChange={() => setDisableMarkdown(!disableMarkdown)} />
								</InputLabel>
								<SolutionHistory
									eventName={event ? event.name : undefined}
									isTeacher={false}
									solutionComments={solutionComments}
									maxPoints={challenge?.maxPoints || 0}
									isDisabled={isFinished(event, challenge)}
									disableQuickGrading={false}
									disableMarkdown={disableMarkdown}
									openSolutionModal={() => setModalOpen(true)}
								/>
							</>
						}
					/>
				),
			});
		}
	} else {
		navTabs.push({
			tabButton: intl.fm("solution.gradinginstruction.title"),
			tabIcon: ErrorOutlineIcon,
			tabContent: (
				<RegularCard
					content={
						gradingInstruction ? (
							<StyledMarkdown source={gradingInstruction.content} darkMode={darkMode} />
						) : (
							<NoData />
						)
					}
				/>
			),
		});
	}

	const hideTabs = navTabs.length + tabLinks.length <= 1;

	tabLinks.push({
		text: "Bug Report",
		icon: BugReport,
		openTab: activeTab,
		onClicked: () => window.open(getSupportLink(challenge), "_blank"),
	});

	return (
		<>
			<NavPills
				alignCenter={true}
				color={"info"}
				active={activeTab}
				onChange={(index: number) => {
					setActiveTab(index);
				}}
				tabs={navTabs}
				tabLinks={tabLinks}
				hideTabs={hideTabs}
			/>
			{hasSubmitSolutionButton && (
				<SolutionSubmitDialog
					eventUnit={challenge}
					challengeId={challengeId}
					isOpen={isModalOpen}
					isTeacher={false}
					onSubmit={postSolutionComment}
					onSubmitGradingCriteriaList={(criteriaCatalog: CriteriaGradingCatalogDto) => {}}
					closeModal={() => {
						setModalOpen(false);
					}}
					onSuccess={() => {
						fetchChallenge(challenge.id);
						setActiveTab(1); // we set the active tab to solution history after submitting a solution
					}}
				/>
			)}
		</>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		infoCardFooter: {
			display: "flex",
			justifyContent: "space-between",
		},
		solutionModalActions: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
		},
		imageCard: { padding: "0!important" },
		imageContainer: {
			overflow: "hidden",
			marginBottom: 20,
		},
		image: {
			borderRadius: 6,
			overflow: "hidden",
			maxWidth: 400,
			maxHeight: 240,
		},
		tooltip: {
			fontSize: "1.2em",
			letterSpacing: ".05em",
		},
		rightField: { float: "right" },
		title: {
			fontWeight: 500,
			color: "#888",
			fontSize: "1.6em",
			marginTop: 0,
			marginBottom: 5,
		},
		cardCollapseButton: {
			flex: 0,
			justifyContent: "flex-end",
			padding: "0 15px",
			margin: 0,
		},
		solutionStatus: {
			display: "flex",
			alignItems: "center",
		},
		solutionPoints: { marginLeft: 10 },
		flagInputContainer: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
		},
		flagInputCard: { marginBottom: 0 },
		flagInputCardContent: { display: "flex" },
		monoSpaced: { fontFamily: "Menlo, Monaco, Consolas, \"Courier New\", monospace" },
		historyMarkdownLabel: {
			fontSize: ".9em",
			marginLeft: "auto",
			marginTop: -15,
			width: "fit-content",
		},
	}),
);

function getSupportLink(challenge: EventUnitChallenge) {
	return `https://hacking-lab.atlassian.net/servicedesk/customer/portal/4/create/65?customfield_10084=${challenge.title}&customfield_10086=${window.location.href}`;
}
